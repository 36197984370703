import { css } from "styled-components"
import { breakpoints, breakpointsMedia } from "../variables/Breakpoint"

const IconButtonCSS = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  color: var(--color-text);
  display: block;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  transition: all 0.15s;
  height: 35.22px;
  width: 36px;
  font-size: 16px;
  line-height: 35px;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    height: 47px;
    width: 47px;
    line-height: 47px;
    font-size: 19px;
  }

  &:hover {
    background-color: darken(white, 5%);

    &.facebook {
      color: white;
      background-color: #3b5998;
    }

    &.linkedin {
      color: white;
      background-color: #0e76a8;
    }

    &.twitter {
      color: white;
      background-color: #00acee;
    }
  }
`

export default IconButtonCSS
