import React, { useState } from "react"
import styled from "styled-components"
import classnames from "classnames"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Heading from "@components/ui/Heading"

const SidebarStyle = styled.aside`
  .sidebar-inner {
    padding: 20px;
    position: relative;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      padding: 20px 46px 46px 46px;
    }

    .sidebar-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .icon {
        color: var(--color-secondary);
        font-size: 25px;
        line-height: 40px;
        text-align: right;
      }
    }

    .title {
      margin-top: 0;
      margin-bottom: 0.2em;
    }
  }

  .collapsed-wrap {
    transition: height 0.5s;
  }

  .see-more-form {
    position: absolute;
    bottom: 6%;
    left: 50%;
    z-index: 50;
    transform: translate(-50%, 0);
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s;
  }

  &.collapsed {
    .see-more-form {
      opacity: 1;
      visibility: visible;
    }

    .collapsed-wrap {
      max-height: 325px;
      overflow: hidden;

      &:after {
        content: "";
        background: #fff;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #fff 50%,
          #fff 100%
        );
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 50;
        height: 30%;
      }
    }
  }
`

const Sidebar = ({ title, icon, collapsable, children, afterInner }) => {
  const [showForm, setShowForm] = useState(false)
  const setFormOpen = () => setShowForm(true)

  return (
    <SidebarStyle
      className={classnames("content-sidebar", {
        collapsed: !showForm,
      })}
    >
      <div className="sidebar-wrapped sidebar-inner">
        <div className="sidebar-header">
          {title && (
            <Heading node="h2" underlined>
              {title}
            </Heading>
          )}
          <div className="icon">{icon}</div>
        </div>

        <div
          className={classnames({
            "collapsed-wrap": collapsable,
          })}
        >
          {children}
        </div>

        {collapsable && !showForm && (
          <div
            className="btn orange see-more-form"
            onClick={setFormOpen}
            onKeyDown={setFormOpen}
            role="button"
            tabIndex={0}
          >
            click to see more
          </div>
        )}
      </div>
      {afterInner}
    </SidebarStyle>
  )
}

Sidebar.propTypes = {}

export default Sidebar
