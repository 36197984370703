import React from "react"
import SocialLinks from "@components/ui/social/SocialLinks"
import Sidebar from "./Sidebar"

const JobSidebar = ({ children, uri }) => {
  return (
    <Sidebar
      title="Apply now"
      icon={<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                 data-icon="briefcase" className="svg-inline--fa fa-briefcase fa-w-16" role="img" viewBox="0 0 512 512">
        <path fill="currentColor"
              d="M320 336c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h416c25.6 0 48-22.4 48-48V288H320v48zm144-208h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128v32z" />
      </svg>}
      afterInner={
        <div
          className="share "
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1000"
        >
          <span className="top">Share this job on:</span>
          <SocialLinks uri={uri} />
        </div>
      }
    >
      {children}
    </Sidebar>
  )
}

JobSidebar.propTypes = {}

export default JobSidebar
