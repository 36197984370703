import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import useSiteMetadata from "@components/utils/use-site-metadata"
import SocialLinkData from "../../../configs/SocialLinkData"
import SocialLink from "@styles/atoms/SocialLink"
import IconButton from "@styles/atoms/IconButton"
import useMobile from "@components/utils/useMobile"

const Sharelinks = ({ socialLinks }) => {
  const openPopup = ({ popup, href }) => {
    if (typeof window === "undefined") return
    if (typeof window.screen === "undefined") return

    if (!popup) {
      return
    }

    const Config = {
      Width: 500,
      Height: 500,
      px: 0,
      py: 0,
    }

    const popupWindow = window.open(
      href,
      "social",
      "width=" +
        Config.Width +
        ",height=" +
        Config.Height +
        ",left=" +
        Config.px +
        ",top=" +
        Config.py +
        ",location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1"
    )

    if (popupWindow) {
      popupWindow.focus()
    }
  }

  return (
    <ul className="share-links">
      {socialLinks.map((socialLink, key) => (
        <li key={key}>
          <SocialLink
            rel="nofollow noreferrer"
            href={socialLink.href}
            onClick={() => openPopup(socialLink)}
            className={socialLink.id + "  share-link"}
            target="_blank"
          >
            {socialLink.icon}
          </SocialLink>
        </li>
      ))}
    </ul>
  )
}

const WebShare = ({ title, url }) => {
  const openWebShare = async () => {
    const shareData = {
      title: title,
      url: url,
    }

    try {
      await navigator.share(shareData)
    } catch (err) {}
  }

  const WebShareLink = styled.a`
    ${IconButton}
  `

  return (
    <WebShareLink onClick={() => openWebShare()}>
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
           data-icon="share-alt" className="svg-inline--fa fa-share-alt fa-w-14" role="img" viewBox="0 0 448 512">
        <path fill="currentColor"
              d="M352 320c-22.608 0-43.387 7.819-59.79 20.895l-102.486-64.054a96.551 96.551 0 0 0 0-41.683l102.486-64.054C308.613 184.181 329.392 192 352 192c53.019 0 96-42.981 96-96S405.019 0 352 0s-96 42.981-96 96c0 7.158.79 14.13 2.276 20.841L155.79 180.895C139.387 167.819 118.608 160 96 160c-53.019 0-96 42.981-96 96s42.981 96 96 96c22.608 0 43.387-7.819 59.79-20.895l102.486 64.054A96.301 96.301 0 0 0 256 416c0 53.019 42.981 96 96 96s96-42.981 96-96-42.981-96-96-96z" />
      </svg>
    </WebShareLink>
  )
}

const SocialLinks = props => {
  const hasShare = typeof navigator !== "undefined" ? navigator.share : false

  const { isMobile } = useMobile()
  const { siteUrl } = useSiteMetadata()

  let url = ""

  if (props.uri) {
    url = siteUrl + props.uri
  } else if (props.url) {
    url = props.url
  }

  const socialLinks = SocialLinkData(url)

  return (
    <div className="share-links-wrap">
      {hasShare && isMobile ? (
        <WebShare {...props} url={url} />
      ) : (
        <Sharelinks {...props} socialLinks={socialLinks} />
      )}
    </div>
  )
}

SocialLinks.propTypes = {
  uri: PropTypes.string,
  url: PropTypes.string,
}

export default SocialLinks
