import React from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "@components/ui/Breadcrumbs"
import SocialLinks from "@components/ui/social/SocialLinks"
import { breadcrumbsShape } from "@components/utils/shapes"

const HeroHead = ({ hasHome, breadcrumbs, uri, hasShare }) => {
  return (
    <div className="hero-head">
      <Breadcrumbs home={hasHome} crumbs={breadcrumbs} />
      {hasShare && (
        <div className="share">
          <span className="top">Share:</span>
          <SocialLinks uri={uri} />
        </div>
      )}
    </div>
  )
}

HeroHead.propTypes = {
  breadcrumbs: breadcrumbsShape,
  hasShare: PropTypes.bool,
}

HeroHead.defaultProps = {
  hasShare: true,
}

export default HeroHead
