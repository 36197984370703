import React, { useContext } from "react"
import { FormContext } from "../FormContext"

export const HiddenField = ({name, value}) => {
  const form = useContext(FormContext)
  const { register } = form

  return (
    <input
      name={name}
      type="hidden"
      value={value}
      ref={register()}
    />
  )
}
