import React from "react"
import styled from "styled-components"
import { FontFamily } from "@styles/variables/FontFamily"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"

const Banner = styled.section`
  box-shadow: inset 0 4px 5px 1px rgba(0, 0, 0, 0.04);
  background-color: #f8f7f7;
  padding: 63px 0;

  &.light {
    box-shadow: none;
    background-color: #fff;
  }

  .container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .banner-text,
  .banner-text span {
    color: var(--color-secondary);
    text-shadow: 3px 4px 0 var(--color-secondary-darkened);
    font-family: ${FontFamily.Heading};
    font-weight: 800;
    font-size: 41px;
    line-height: 49px;
    text-align: left;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      text-align: center;
      font-size: 75px;
      line-height: 74px;
      text-shadow: 5px 5px 0 var(--color-secondary-darkened);
    }
  }

  .pulse-ani {
    animation: flash 3s infinite;
  }
`

const NoRecruiters = ({ isLight }) => {
  return (
    <Banner className={isLight ? "light" : ""}>
      <div className="container " data-aos="zoom-in" data-aos-duration="1000">
        <span className="banner-text">
          NO RECRUITERS PLEASE<span className="pulse-ani">!</span>
        </span>
      </div>
    </Banner>
  )
}

NoRecruiters.defaultProps = {
  isLight: true,
}

export default NoRecruiters
