import React from "react"
import styled from "styled-components"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"
import JobSidebar from "./sidebar/JobSidebar"
import JobApplicationForm from "../forms/JobApplicationForm"

const JobContentStyle = styled.section`
  padding: 35px 0;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    padding: 65px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${breakpointsMedia(breakpoints.desktop)} {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .job-content {
    flex: 1;

    ul {
      margin-left: 1.2rem;
      margin-top: 7px;
      list-style: none;

      li {
        position: relative;
        margin-bottom: 0.5em;

        &:before {
          content: "-";
          color: var(--color-secondary);
          position: absolute;
          left: -20px;
          font-weight: 600;
        }
      }
    }
  }

  aside {
    flex: 0 0 467px;
    width: 467px;
    margin-top: 30px;
    max-width: 100%;

    ${breakpointsMedia(breakpoints.desktop)} {
      margin-top: 0;
      margin-left: 52px;
    }

    .share {
      display: flex;
      align-content: center;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-top: 52px;

      .top {
        font-weight: 600;
      }
    }
  }
`

const JobContent = ({ uri, content, netlifyFormId }) => {
  return (
    <JobContentStyle>
      <div className="container">
        <div
          className="job-content "
          data-aos="fade"
          data-aos-duration="1000"
          data-aos-delay="500"
          dangerouslySetInnerHTML={{ __html: content }}
        />

        <JobSidebar uri={uri}>
          <JobApplicationForm uri={uri} netlifyFormId={netlifyFormId} />
        </JobSidebar>
      </div>
    </JobContentStyle>
  )
}

JobContent.propTypes = {}

export default JobContent
