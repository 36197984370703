import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Seo from "@components/Seo"
import JobHero from "@components/sections/heros/JobHero"
import NoRecruiters from "@components/sections/NoRecruiters"
import JobContent from "@components/sections/JobContent"

export default function JobSingle({ data }) {
  const page = data.allWpJob.nodes[0]

  return (
    <>
      <Seo {...page.seo} />
      <Helmet
        bodyAttributes={{
          class: "short"
        }}
      />

      <JobHero
        title={page.title}
        uri={page.uri}
        jobType={page.acfJobfields?.jobType}
      />
      <JobContent uri={page.uri} content={page.content} netlifyFormId={page.acfJobfields?.netlifyFormId} />
      <NoRecruiters isLight={false} />
    </>
  )
}

export const query = graphql`query ($slug: String!) {
  allWpJob(filter: {slug: {eq: $slug}}) {
    nodes {
      id
      uri
      title
      link
      content
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED)
            }
          }
        }
        title
        twitterDescription
        twitterTitle
        schema {
          raw
        }
      }
      acfJobfields {
        jobType
        blogCategories {
          name
          id
        }
        netlifyFormId
      }
    }
  }
}
`
