import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"
import { helmetJsonLdProp } from "react-schemaorg"
import Helmet from "react-helmet"
import useSiteMetadata from "@components/utils/use-site-metadata"

import styled from "styled-components"
import { FontFamily } from "@styles/variables/FontFamily"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"

const BreadcrumbsStyled = styled.div`
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  font-family: ${FontFamily.Heading};

  ${breakpointsMedia(breakpoints.tabletWide)} {
    font-size: 18px;
    line-height: 27px;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    display: inline-block;

    &,
    a {
      color: var(--color-secondary);
    }

    &:after {
      content: "<";
      color: var(--color-text);
      margin: 0 12px;
      font-family: cursive;
      font-size: 18px;
      font-weight: 300;
    }

    a,
    a:hover,
    a:focus {
      text-decoration: none;
    }

    i {
      margin-right: 6px;
    }
  }

  li:last-child {
    &:after {
      display: none;
    }

    &,
    a {
      color: var(--color-text);
      text-decoration: none;
      font-weight: 600;
    }
  }

  .sep {
    display: none;
  }
`

const Breadcrumbs = ({ crumbs, home }) => {
  let breadcrumbs = crumbs

  if (home) {
    breadcrumbs = [
      {
        url: "/",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="house"
            className="svg-inline--fa fa-house fa-w-18"
            role="img"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M570.69,236.27,512,184.44V48a16,16,0,0,0-16-16H432a16,16,0,0,0-16,16V99.67L314.76,10.3C308.48,4.61,296.51,0,288,0s-20.45,4.61-26.73,10.3l-256,226a16,16,0,0,0-1.2,22.6l21.4,23.8A18.31,18.31,0,0,0,37.42,288a18.51,18.51,0,0,0,10.69-4.11l15.9-14V480a32,32,0,0,0,32,32H480a32,32,0,0,0,32-32V269.87l15.91,14A18.62,18.62,0,0,0,538.63,288a18.12,18.12,0,0,0,11.87-5.31l21.41-23.81A18.61,18.61,0,0,0,576,248.18,18.21,18.21,0,0,0,570.69,236.27ZM352,298.66A21.39,21.39,0,0,1,330.64,320h-85.3A21.39,21.39,0,0,1,224,298.66V213.32A21.39,21.39,0,0,1,245.34,192h85.3A21.39,21.39,0,0,1,352,213.32Z"
            />
          </svg>
        ),
        title: "Home",
        isHighlight: true,
      },
      ...breadcrumbs,
    ]
  }

  const { siteUrl } = useSiteMetadata()

  const breadcrumbSchema = breadcrumbs?.map((breadcrumb, key) => {
    const crumb = {
      "@type": "ListItem",
      position: key + 1,
      name: breadcrumb.title,
    }

    if (breadcrumb.url) {
      return { ...crumb, item: siteUrl + breadcrumb.url }
    }

    return crumb
  })

  return (
    <>
      {breadcrumbSchema && (
        <Helmet
          script={[
            helmetJsonLdProp({
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: breadcrumbSchema,
            }),
          ]}
        />
      )}
      <BreadcrumbsStyled className="breadcrumbs">
        <ul>
          {breadcrumbs.map((node, key) => {
            const name = node.icon ? (
              <span title={node.title}>{node.icon}</span>
            ) : (
              node.title
            )
            return (
              <li
                key={key}
                className={classNames({ highlight: node.isHighlight })}
                title={node.title}
              >
                {node.url ? <Link to={node.url}>{name}</Link> : <>{name}</>}
              </li>
            )
          })}
        </ul>
      </BreadcrumbsStyled>
    </>
  )
}

Breadcrumbs.defaultProp = {
  home: true,
  crumbs: [],
}
Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string.isRequired,
      isHighlight: PropTypes.bool,
    })
  ).isRequired,
}

export default Breadcrumbs
