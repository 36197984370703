import React from "react"

import { HeroStyleConstrained } from "@styles/organisms/Hero"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { FontFamily } from "@styles/variables/FontFamily"
import HeroHead from "./HeroHead"
import Heading from "@components/ui/Heading"

const JobHeroStyle = styled(HeroStyleConstrained)`
  min-height: 372px;
  height: 372px;

  .pre-title {
    font-family: ${FontFamily.Heading};
    font-size: 23px;
    line-height: 36px;
    font-weight: 600;
  }

  .pre-title-wrap {
    overflow: hidden;
  }

  .question-mark {
    font-family: ${FontFamily.Heading};
    font-size: 43px;
    line-height: 36px;
    font-weight: 600;
  }

  .breadcrumbs {
    ${breakpointsMedia(breakpoints.desktopSM, "max")} {
      li:not(:first-child),
      li:after {
        display: none;
      }
    }
  }
`

export default function JobHero({ title, uri, jobType }) {
  const breadcrumbs = [
    {
      url: "/work-for-us/",
      title: "Work for us",
      isHighlight: true,
    },
    {
      title: title,
    },
  ]

  return (
    <JobHeroStyle>
      <div className="container">
        <div className={`bg-icons ${jobType}`}>
          <i>
            <span
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
              className=" "
            />
          </i>
          <i className="sm">
            <span
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="550"
              className=" "
            />
          </i>
          <i className="sm">
            <span
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="600"
              className=" "
            />
          </i>
          <i className="sm">
            <span
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
              className=" "
            />
          </i>
          <i>
            <span
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="550"
              className=" "
            />
          </i>
          <i className="sm">
            <span
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="600"
              className=" "
            />
          </i>
        </div>
        <HeroHead breadcrumbs={breadcrumbs} uri={uri} />
        <div className="inner">
          <div className="pre-title-wrap">
            <div
              className="pre-title "
              data-aos="fade"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              Could you be our new
            </div>
          </div>
          <div className="title-wrap">
            <Heading
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              {title}
            </Heading>
          </div>
          <div
            className="question-mark "
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            ?
          </div>
        </div>
      </div>
    </JobHeroStyle>
  )
}
