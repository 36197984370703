import React, { useState } from "react"
import { Link } from "gatsby"
import { AjaxForm } from "./AjaxForm"
import { EmailInput } from "./form-fields/EmailInput"
import { TextArea } from "./form-fields/TextArea"
import { FileInput } from "./form-fields/FileInput"
import { SingleCheckbox } from "./form-fields/SingleCheckbox"
import { TextInput } from "./form-fields/TextInput"
import { HiddenField } from "@components/forms/form-fields/HiddenField"

const JobApplicationForm = ({ uri, netlifyFormId }) => {
  const [formSubject, setFormSubject] = useState("")

  const formName = netlifyFormId ? `job_application_${netlifyFormId}` : "job_application"

  return (
    <AjaxForm
      formName={formName}
      formSubject={formSubject}
      submitText={"Apply"}
      onSubmitOk={data => {
        window.dataLayer.push({ event: "gtm.jobForm" })
        window.dataLayer.push({
          event: "gtm.careersFormSubmit"
        })
      }}
    >
      <HiddenField name="JOB_URL" value={uri} />
      <div className="form-row">
        <TextInput
          label="First Name"
          name="first_name"
          placeholder="Your first name here..."
          required={true}
          maxLength={100}
        />
      </div>

      <div className="form-row">
        <TextInput
          label="Last Name"
          name="last_name"
          placeholder="Your last name here..."
          required={true}
          maxLength={100}
        />
      </div>

      <div className="form-row">
        <EmailInput
          label="Email"
          name="email"
          placeholder="Your email here..."
          required={true}
          maxLength={100}
          onChange={value => setFormSubject(value)}
        />
      </div>

      <div className="form-row">
        <TextInput
          label="Website"
          name="website"
          placeholder="Your website URL here..."
          maxLength={100}
        />
      </div>

      <div className="form-row">
        <TextInput
          label="Phone Number"
          name="phone_number"
          placeholder="Your phone number here..."
          required={true}
          maxLength={100}
        />
      </div>

      <div className="form-row">
        <TextInput
          label="Link to portfolio"
          name="portfolio_link"
          description="Or upload it below"
          maxLength={100}
        />
      </div>

      <div className="form-row">
        <FileInput
          label="Upload portfolio"
          name="portfolio_file"
          description="PDF, Word"
          accept={["pdf", "docx"]}
          maxSize={1}
        />
      </div>

      <div className="form-row">
        <FileInput
          label="Upload CV"
          name="cv_file"
          description="PDF, Word"
          accept={["pdf", "docx"]}
          required={true}
          maxSize={1}
        />
      </div>

      <div className="form-row">
        <TextArea
          label="Your Message"
          name="your_message"
          placeholder="Your message here..."
          required={true}
          maxLength={2000}
        />
      </div>

      <div className="form-row">
        <SingleCheckbox
          label={
            <span>
              I have read and accept
              <Link
                target="_blank"
                to="/privacy-policy/"
                style={{ marginLeft: "5px" }}
              >
                T&amp;C
              </Link>
            </span>
          }
          name="t_and_c"
          required={true}
          description="Or upload it below"
        />
      </div>
    </AjaxForm>
  )
}

JobApplicationForm.propTypes = {}

export default JobApplicationForm
